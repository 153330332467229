"use strict";

let sliderId = 0;

import {onEnterViewPort} from "@elements/viewport-utils";
import {loadImg} from "./lazyImg";

export function initInScope ($scope) {
    let $sliders = $scope.find('.js-feature-slider');

    import('slick-carousel').then(function() {
        $sliders.each(function() {
            let $sliderWrapper = $(this);
            let $sliderMain = $(this).find('.js-feature-slider__main');
            let $sliderNav = $(this).find('.js-feature-slider__nav');
            let hasThumbs = $(this).attr('data-slider-thumbs');
            let id = sliderId++;

            $sliderMain.attr('id', 'feature-slider__main-' + id);
            $sliderNav.attr('id', 'feature-slider__thumb-' + id);

            onEnterViewPort($sliderWrapper, function () {
                preloadSlide($sliderMain.slick('getSlick'), (matchMedia('(max-width: 767px)').matches ? 2: 1));
            });

            $sliderMain.on('afterChange', function(event, slick, currentSlide) {
                preloadSlide(slick, currentSlide + (matchMedia('(max-width: 767px)').matches ? 2: 1));
                preloadSlide($sliderMain.slick('getSlick'), currentSlide -1);
            }).slick({
                asNavFor: hasThumbs === 'true' ? '#feature-slider__thumb-' + id : null,
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: true,
                prevArrow: $sliderWrapper.find('.slick-slider__arrow--primary-square.feature-slider__arrow.arrow-prev'),
                nextArrow: $sliderWrapper.find('.slick-slider__arrow--primary-square.feature-slider__arrow.arrow-next'),
                dots: true,
                fade: false,
                infinite: false,
                cssEase: 'cubic-bezier(0.4, 0, 0.3, 1)',
                speed:1200,
                focusOnSelect: true,
                responsive: [{
                    breakpoint: 767,
                    settings: {
                        asNavFor: null,
                        speed:600,
                    }
                }]
            });

            if(matchMedia('(min-width: 768px)').matches && hasThumbs === 'true') {
                if($sliderNav && $sliderNav.length) {
                    $sliderNav.slick({
                        dots: true,
                        arrows: false,
                        slidesToScroll: 1,
                        slidesToShow: 8,
                        infinite: false,
                        centerMode: false,
                        variableWidth: true,
                        asNavFor: '#feature-slider__main-' + id,
                        focusOnSelect: true,
                    });
                }
            }
        });
    })
}

export function preloadSlide(slick, slideIndex) {
    let slideToPreload = slick.$slides[slideIndex];

    if (slideToPreload) {
        let $imgToPreload = $(slideToPreload).find('.js-lazy-img');
        $imgToPreload.each(function() {
            let $img = $(this);
            if ($img && $img.length) {
                loadImg($img);
            }
        })

    }
}

