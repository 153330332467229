import $ from 'jquery';

// import 'popper.js';
import 'bootstrap/js/dist/alert';
// import 'bootstrap/js/dist/button';
// import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
// import 'bootstrap/js/dist/modal';
// import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/scrollspy';
import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/toast';
// import 'bootstrap/js/dist/tooltip';

if (!window.HTMLPictureElement) {
    import('picturefill');
}

console.log("SCRIPT");
let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};
window.app.modules =  app.modules || {};

import initModulesInScope from "@elements/init-modules-in-scope";
app.initModules = function ($scope) {
    console.warn('\'app.initModules\' is going to be deprecated and should not be used anymore. Please import and use \'initModulesInScope\' from \'./scripts/initModulesInScope\' instead.');
    initModulesInScope($scope);
};

$.loadScript = function (url) {
    console.warn('\'$.loadScript\' is going to be deprecated and should not be used anymore. Please use \'import\' for local files or import and use \'loadScript\' for external resources.');

    return import('@elements/load-script').then(function (loadScript) {
        return loadScript.default(url);
    });
};

/**********************************
 *
 *      Project Stuff
 *
 * ********************************/

import * as ajaxInclude from './scripts/ajaxInclude';
app.modules.ajaxInclude = ajaxInclude.initInScope;

import * as goToLink from '@elements/go-to-link';
app.goToLink = goToLink.init;

import * as toc from '@elements/toc';
app.toc = toc.init;
app.modules.toc = toc.initInScope;

import * as recaptcha from './scripts/recaptcha';
app.modules.recaptcha = recaptcha.initInScope;

import * as lazyImg from './scripts/lazyImg';
app.modules.lazyImg = lazyImg.initInScope;

import * as shariff from './scripts/shariff';
app.modules.shariff = shariff.initInScope;

import * as videoJs from './scripts/videoJs';
app.modules.videoJs = videoJs.initInScope;

import * as featureSlider from './scripts/featureSlider';
app.modules.featureSlider = featureSlider.initInScope;

import * as heroBlogSlider from './scripts/heroBlogSlider';
app.modules.heroBlogSlider = heroBlogSlider.initInScope;

import * as imgSlider from './scripts/imgSlider';
app.modules.imgSlider = imgSlider.initInScope;

import * as lightbox from './scripts/lightbox';
app.modules.lightbox = lightbox.initInScope;

import * as isInViewport from './scripts/isInViewport';
app.modules.isInViewport = isInViewport.initInScope;

import * as parallax from './scripts/parallax';
app.modules.parallax = parallax.initInScope;

import * as fadeIn from './scripts/fadeIn';
app.modules.fadeIn = fadeIn.initInScope;

import * as scaleIn from './scripts/scaleIn';
app.modules.scaleIn = scaleIn.initInScope;

import * as heroSlider from './scripts/heroSlider';
app.modules.heroSlider = heroSlider.initInScope;

import * as affixMedia from './scripts/affixMedia';
app.modules.affixMedia = affixMedia.initInScope;

import * as socialWall from './scripts/socialWall';
app.modules.socialWall = socialWall.initInScope;

import * as fileInput from '@elements/file-input';
app.modules.fileInput = fileInput.initInScope;

import * as imgPreview from './scripts/imgPreview';
app.modules.imgPreview = imgPreview.initInScope;

// import * as affix from '@elements/affix';
// app.modules.affix = affix.createInitInScope({
//     mediaQuery: '(min-width: 768px)'
// });

import * as expandable from './scripts/expandable';
app.modules.expandable = expandable.initInScope;

import * as accessibilityNav from '@elements/accessibility-nav';
app.modules.accessibilityNav = accessibilityNav.createInitInScope({base:'.js-nav', item:'.js-nav__item'});

import * as touchNav from '@elements/touch-nav';
app.modules.touchNav = touchNav.initInScope;

import * as nav from './scripts/nav';
app.modules.nav = nav.init;

import * as affixNavbar from './scripts/affixNavbar';
app.affixNavbar = affixNavbar.init;
app.modules.affixNavbar = affixNavbar.initInScope;

import * as regionHotspots from './scripts/regionHotspots';
app.modules.regionHotspots = regionHotspots.initInScope;

import * as setDatepicker from './scripts/setDatepicker';
app.modules.setDatepicker = setDatepicker.initInScope;

import * as datepicker from './scripts/datepicker';
app.modules.datepicker = datepicker.initInScope;

import * as floatingLabels from '@elements/floating-labels';
app.modules.floatingLabels = floatingLabels.initInScope;

import * as datepickerRange from './scripts/datepicker-range';
app.modules.datepickerRange = datepickerRange.initInScope;

import * as numberSpinner from './scripts/numberSpinner';
app.modules.numberSpinner = numberSpinner.initInScope;

import * as ticketCalculator from './scripts/ticket-calculator';
app.modules.ticketCalculator = ticketCalculator.initInScope;

import * as nestedCheckbox from './scripts/nested-checkbox';
app.modules.nestedCheckbox = nestedCheckbox.initInScope;

import * as setSelect from './scripts/setSelect';
app.modules.setSelect = setSelect.initInScope;

import * as setHiddenInput from './scripts/setHiddenInput';
app.modules.setHiddenInput = setHiddenInput.initInScope;

import * as accommodationRequest from './scripts/accommodation-request';
app.modules.accommodationRequest = accommodationRequest.initInScope;

import * as datepickerSimple from '@elements/datepicker';
import {UTCDateToLocalDate} from "@elements/date-utils";
app.modules.birthdayDatepicker = datepickerSimple.createInitInScope({
    base: '.js-birthday-datepicker',
    input: '.js-birthday-datepicker__input',
    altField: '.js-birthday-datepicker__alt-field'
}, {
    changeMonth: true,
    changeYear: true,
    yearRange: "1900:+nn",
    maxDate: UTCDateToLocalDate(new Date($('.js-birthday-datepicker').attr('data-datepicker-max-date'))),
    minDate: UTCDateToLocalDate(new Date($('.js-birthday-datepicker').attr('data-datepicker-min-date')))
});


import * as ticketContingent from './scripts/ticket-contingent';
app.modules.ticketContingent = ticketContingent.initInScope;

// import * as parsley from '@elements/parsley-bootstrap-validation';
// app.modules.parsley = parsley.initInScope;

import * as parsley from './scripts/custom-parsley-bootstrap-validation';
app.modules.parsley = parsley.initInScope;

import * as showIfChecked from './scripts/showIfChecked';
app.modules.showIfChecked = showIfChecked.initInScope;

import * as countdown from './scripts/countdown';
app.modules.countdown = countdown.initInScope;

import * as overlay from './scripts/overlay';
app.overlay = overlay.init;
app.modules.overlay = overlay.initInScope;

import * as loadMore from './scripts/loadMore';
app.modules.loadMore = loadMore.initInScope;

import * as scrollWaypoints from './scripts/scrollWaypoints';
app.modules.scrollWaypoints = scrollWaypoints.initInScope;

import * as quickfinder from './scripts/quickfinder';
app.modules.quickfinder = quickfinder.initInScope;

import * as affix from '@elements/affix';
app.modules.affix = affix.initInScope;

import * as slider from '@elements/slider';
app.modules.slider = slider.initInScope;

import * as backButton from './scripts/backButton';
app.modules.backButton = backButton.initInScope;

import * as iframeSizeBridge from './scripts/iframe-size-bridge';
app.iframeSizeBridge = iframeSizeBridge.init;

import * as actionChanger from '@elements/action-changer';
app.modules.actionChanger = actionChanger.initInScope;

app.modules.imgTeaserSlider = slider.createInitInScope('.js-img-teaser-slider', {
    slidesToShow: 4,
    prevArrow: '<button type="button" class="teaser-slider__arrow slider__arrow arrow-prev btn-no-styling" aria-label="Prev" title="Prev"><span class="icon icon-arrow icon-rotate-90" aria-hidden="true"></span></button>\n',
    nextArrow: '<button type="button" class="teaser-slider__arrow slider__arrow arrow-next btn-no-styling" aria-label="Next" title="Next"><span class="icon icon-arrow icon-rotate-270"></span></button>\n',
    responsive: [
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1.4,
            }
        },
    ]
});

app.modules.bloggerTeaserSlider = slider.createInitInScope('.js-blogger-teaser-slider', {
    slidesToShow: 6,
    prevArrow: '<button type="button" class="teaser-slider__arrow slider__arrow arrow-prev btn-no-styling" aria-label="Prev" title="Prev"><span class="icon icon-arrow icon-rotate-90" aria-hidden="true"></span></button>\n',
    nextArrow: '<button type="button" class="teaser-slider__arrow slider__arrow arrow-next btn-no-styling" aria-label="Next" title="Next"><span class="icon icon-arrow icon-rotate-270"></span></button>\n',
    responsive: [
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1.6,
            }
        },
    ]
});

app.modules.partnerLogoSlider = slider.createInitInScope('.js-partner-logo-slider', {
    slidesToShow: 5,
    autoplay: true,
    autoplaySpeed: 2000,
    infinite: true,
    prevArrow: '<button type="button" class="teaser-slider__arrow teaser-slider__arrow-small slider__arrow arrow-prev btn-no-styling" aria-label="Prev" title="Prev"><span class="icon icon-arrow icon-rotate-90" aria-hidden="true"></span></button>\n',
    nextArrow: '<button type="button" class="teaser-slider__arrow teaser-slider__arrow-small slider__arrow arrow-next btn-no-styling" aria-label="Next" title="Next"><span class="icon icon-arrow icon-rotate-270"></span></button>\n',
    responsive: [
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                centerMode: true,
            }
        },
    ]
});

app.modules.adTeaserSlider = slider.createInitInScope('.js-ad-teaser-slider', {
    slidesToShow: 4,
    adaptiveHeight: true,
    prevArrow: '<button type="button" class="teaser-slider__arrow slider__arrow arrow-prev btn-no-styling" aria-label="Prev" title="Prev"><span class="icon icon-arrow icon-rotate-90" aria-hidden="true"></span></button>\n',
    nextArrow: '<button type="button" class="teaser-slider__arrow slider__arrow arrow-next btn-no-styling" aria-label="Next" title="Next"><span class="icon icon-arrow icon-rotate-270"></span></button>\n',
    responsive: [
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1.4,
            }
        },
    ]
});

import * as ajaxForm from './scripts/ajaxForm';
app.modules.ajaxForm = ajaxForm.initInScope;

import * as scrollTo from './scripts/scrollTo';
app.modules.scrollTo = scrollTo.initInScope;

import * as showOnClick from './scripts/showOnClick';
app.modules.showOnClick = showOnClick.initInScope;

import * as tableResponsive from './scripts/table-responsive';
app.modules.tableResponsive = tableResponsive.initInScope;

import * as ajaxFormScroll from './scripts/ajaxFormScroll';
app.modules.ajaxFormScroll = ajaxFormScroll.initInScope;

import * as tracking from "@elements/tracking";
let options = {}; // optional options
tracking.initGtmEventTracking(options); // for GTM tracking

import * as openModalByDefault from './scripts/openModalByDefault';
app.openModalByDefault = openModalByDefault.init;

import * as openModalIfUnchecked from './scripts/openModalIfUnchecked';
app.modules.openModalIfUnchecked = openModalIfUnchecked.initInScope;

import * as cropper from './scripts/cropper';
app.modules.cropper = cropper.initInScope;

import * as parsleyExtended from './scripts/parsley-extended';
app.modules.parsleyExtended = parsleyExtended.initInScope;

import * as formErrorSuccessAnchor from './scripts/form-error-success-anchor';
app.modules.formErrorSuccessAnchor = formErrorSuccessAnchor.initInScope;

import * as anchorAccordion from './scripts/anchor-accordion';
app.modules.anchorAccordion = anchorAccordion.initInScope;

import * as birthdayInput from './scripts/birthdayInput';
app.modules.birthdayInput = birthdayInput.initInScope;

import * as resetBirthdayInput from './scripts/reset-birthday-input';
app.modules.resetBirthdayInput = resetBirthdayInput.initInScope;

// only for demi
app.filterForm = function () {
    import('./scripts/filter-form/filter-form').then(function (filterForm) {
        app.modules.filterForm = filterForm.initInScope;

        filterForm.init();
        filterForm.initInScope($('body'));
    });
};

import * as reloadForm from "@elements/reload-form";
app.modules.reloadForm = reloadForm.initInScope;

import * as dataReporter from "./scripts/datareporter"
app.modules.dataReporter = dataReporter.initInScope;

import * as hashCash from "./scripts/hashCash"
app.modules.hashCash = hashCash.initInScope;

import {init} from '@elements/alert-notification';
init({
    // default options
});


import { showCookieBar } from "@elements/cookie-permissions";
// if (!_config.admin && !_config.editmode) {
//     showCookieBar();
// }

import * as adTeaserTracking from './scripts/adTeaserTracking';
app.modules.adTeaserTracking = adTeaserTracking.initInScope;


import * as cookieModalHelpers from './scripts/cookieModalHelpers';
app.modules.cookieModalHelpers = cookieModalHelpers.initInScope;

import * as cookieServiceHelpers from './scripts/cookieServiceHelpers';
app.cookieServiceHelpers = cookieServiceHelpers.init;

(function( $ ){
    "use strict";

    /* -> _config._preload = Load this functions first */
    if (_config['_preload']) {
        $.each( _config['_preload'], function( _key, _val ){
            if( typeof _val == 'boolean' && typeof window.app[_key] == 'function' ){
                window.app[_key]();
            }
        });
    }

    /* -> _config = Load all others (not _preload and _reload) */
    $.each( _config, function( _key, _val ){
        if( ( typeof _val == 'boolean' && typeof window.app[_key] == 'function' && _key != '_reload' && _key != '_preload' ) ){
            window.app[_key]();
        }
    });

    /* -> _config._reload = Load the ajaxInclued and others after the rest */
    if (_config['_reload']) {
        $.each( _config['_reload'], function( _key, _val ){
            if( ( typeof _val == 'boolean' && typeof window.app[_key] == 'function' ) ){
                window.app[_key]();
            }
        });
    }

    initModulesInScope();
})($);