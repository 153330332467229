"use strict";

import fetch from '@elements/fetch';

export function initInScope($scope) {
    let $wrapper = $scope.find('.js-ticket-contingent');
    let timeOut = $wrapper.attr('data-ticket-contingent-time-out');
    let $result = $scope.find('.js-ticket-contingent__counter');

    if (!_config.ticketContingentUrl) {
        console.warn(new Error('Can\'t fetch ticket-contingent. _config.ticketContingentUrl is not set.'));
    }

    let url = _config.ticketContingentUrl;

    setTimeout(fetchData, timeOut);

    //fetching data every few seconds
    function fetchData() {
        let pendingRequest = fetch( url , {} );

        pendingRequest.then((res) => {
            return res.clone().json()
        }).then((res) => {
            if (res.success) {
                $result.empty();
                $result.text(res.counter);
                setTimeout(fetchData,5000);
            }
        }).catch((error) => {
            if (error.name !== 'AbortError') {
                /*Do error stuff*/
                console.error(error);
            }
        });
    }
}
