"use strict";

import {whenValid} from "@elements/parsley-bootstrap-validation";

export function initInScope($scope) {
    let $forms = $scope.find('.js-open-modal-if-unchecked__form');

    $forms.each(function() {
       let $form = $(this);
       let $checkbox = $form.find('.js-open-modal-if-unchecked__input');
       let isChecked = false;
       let doSubmit = false;
       let $modal = $($form.data('open-modal-if-unchecked-target'));

        const preSubmitButton = $form.find('.js-customer-club__pre-submit');

        if($checkbox.prop('checked')) {
            preSubmitButton.attr('type', 'submit');
        } else {
            preSubmitButton.attr('type', 'button');
        }

        $checkbox.on('change', function(evt) {
            isChecked = evt.target.checked;

            if(isChecked) {
                preSubmitButton.attr('type', 'submit');
            } else {
                preSubmitButton.attr('type', 'button');
            }
       });

        $form.on('submit', function(evt) {
            console.log("submitting form!")
        })

        preSubmitButton.on('click', function(evt) {
           if(!isChecked && !doSubmit) {
               evt.preventDefault();

               whenValid($form).then((isValid) => {
                   if (isValid) {
                       if($modal && $modal.length > 0) {
                           $modal.modal('show');
                           $form.find('.js-open-modal-if-unchecked__loading').attr('hidden', true);
                           $form.find('button[type="submit"], button:not([type])').prop('disabled', false);
                       } else {
                           console.warn('openModalIfUnchecked: Modal ' + $form.data('open-modal-if-unchecked-target') + ' not found!');
                       }
                   }
               });
           }
       })
    });
}
