"use strict";

import picturefill from 'picturefill';
import {onEnterViewPort} from "@elements/viewport-utils";
import {getPrefixedDataSet} from "@elements/data-set-utils";


const selectors = {
    base: '.js-lazy-img',
    backgroundModifier: '.js-lazy-img--bg',
    posterBase: '.js-lazy-img--poster'
};

const options = {
    offset: window.innerHeight / 2
};

const settingsPoster = {
    offset: window.innerHeight
};

export function initInScope ($scope) {
    let $images = $scope.find(selectors.base);
    let $posters = $scope.find(selectors.posterBase);

    onEnterViewPort($posters, function (posterImg) {
        loadPosterImg($(posterImg));
    }, settingsPoster);

    $images = $images.map((_, el) => {
        return $(el).closest('picture').length > 0 ? $(el).closest('picture') : $(el);
    });

    if ('loading' in HTMLImageElement.prototype) {
        $images.each((_, el) => {
            const $image = $(el);

            if($image.is(selectors.backgroundModifier)){
                onEnterViewPort($image, function (img) {
                    loadImg($(img), selectors, options);
                }, options);
            }else{
                // onEnterViewPort($image, function (img) {
                //     loadImg($(img), selectors, options);
                // }, options);
                switchImage($image, true)
            }
        })
    }else{
        $images.each((_, el) => {
            const $image = $(el);

            let elementOptions = {
                ...options,
                ...getPrefixedDataSet('lazy-img', $image)
            };

            onEnterViewPort($image, function (img) {
                console.log("viewport enter", img);
                loadImg($(img), selectors, elementOptions);
            }, elementOptions);
        });
    }

    return $images;
}


export function loadPosterImg($img) {
    $img.attr('poster', $img.data('poster'));
}


export function loadImg($img, selectors = {}) {
    selectors = {...selectors};

    if ($img.is(selectors.backgroundModifier)) {
        // background image
        let url = getPrefixedDataSet('lazy-img', $img).background;
        if (url) {
            $img.css('background-image', 'url(' + url + ')');
        }
    } else {
        // usual image
        switchImage($img);

        picturefill({
            reevaluate: true
        });
    }
}


function switchImage($img, addLazyLoading = false) {
    if ($img.is('picture')) {
        $img.find('source, img').each(function () {
            if(addLazyLoading){
                $img.find('img').attr('loading', 'lazy')
            }
            if ($(this).data('srcset')) {
                $(this).attr('srcset', $(this).data('srcset'));
            }

            if ($(this).data('src')) {
                $(this).attr('src', $(this).data('src'));
            }
        });
    } else {
        if(addLazyLoading){
            $img.attr('loading', 'lazy');
        }

        if ($img.data('srcset')) {
            $img.attr('srcset', $img.data('srcset'));
        }

        if ($img.data('src')) {
            $img.attr('src', $img.data('src'));
        }
    }

}
