"use strict";

import {isElementInViewport} from "@elements/viewport-utils";
import {unsetAffixMedia} from "./affixMedia";

let currentId = 1;

export function initInScope ($scope) {
    let $videos = $scope.find('.js-video-js');

    let settings = {
        offset: window.innerHeight / 2
    };

    import('video.js').then(function (videojs){
        $videos.each(function () {
            let $video = $(this);
            let id = $(this).prop('id');
            let playOnViewportEnter = $(this).attr('data-play-on-viewport-enter');


            if (!id) {
                id = 'video-js-id-' + currentId++;
                $(this).prop('id', id);
            }
            let player = videojs.default(id);
            player.controlBar.liveDisplay.show();

            if(playOnViewportEnter) {
                setInterval(function () {
                    let isPlaying = $video.hasClass('is-playing');
                    if (isElementInViewport(player.el_, settings.offset, true)) {

                        if(!isPlaying){
                            player.play();
                            $video.addClass('is-playing');
                        }
                    } else {
                        if(isPlaying){
                            player.pause();
                            $video.removeClass('is-playing');
                        }
                    } 
                }, 200);
            }

            let isAffixVideo = $video.parent().hasClass('js-affix-media__video');
            if(isAffixVideo  && matchMedia('(min-width: 1200px)').matches) {
                let $wrapper = $video.closest('.js-affix-media');
                player.on('play', function() {
                    $wrapper.addClass('is-playing');
                });
                player.on('pause', function() {
                    $wrapper.removeClass('is-playing');
                });
                player.on('ended', function() {
                    unsetAffixMedia();
                });
            }

            $('.js-affix-media__hide-btn').on('click', function() {
                unsetAffixMedia();
                player.pause();
            });
        });
    });
}

