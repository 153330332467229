"use strict";

import {loadParsley} from '@elements/parsley-bootstrap-validation';
import {getDate} from "./datepicker";
import {dateToISOString, ISOStringToDate, localDateToUTCDate, UTCDateToLocalDate} from "@elements/date-utils";

export function initInScope ($scope) {
    loadParsley().then(function () {
        let passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@!%.:,;*+=\/_()~<>?&#"§\[\]\\-])[A-Za-z\d$@!%.:,;*+=\/_()~<>?&#"§\[\]\\-]{8,}/;

        Parsley.addValidator("password", {
            validateString: function (value, requirement) {
                return value.match(passwordRegex) !== null;
            }
        });
        // Parsley.addValidator("birthday", {
        //     validateString: function (value, requirement, parsleyInstance) {
        //         let birthday = new Date(value);
        //         let minDate = parsleyInstance.$element[0].dataset.parsleyBirthdayMindate;
        //         minDate = new Date(minDate);
        //         return birthday < minDate;
        //     }
        // });
        Parsley.addValidator("minDate", {
            validateString: function(value, minDate, parsleyInstance) {
                let birthday = value;
                // minDate =  new Date(minDateYear, minDateMonth, minDateDay).getTime() / 1000;

                console.log("Birthday & mindate", birthday, minDate);
                return birthday > minDate;
            },
            messages: {
                de: "min. Datum ist " + ' %s',
                en: "min date is " + '%s'
            }
        });

        Parsley.addValidator("maxDate", {
            validateString: function(value, maxDate, parsleyInstance) {
                let birthday = value;
                // maxDate = new Date(maxDateYear, maxDateMonth, maxDateDay).getTime() / 1000;

                let newMaxDate = new Date(maxDate);
                console.log("Birthday & maxdate", birthday, dateToISOString(newMaxDate));
                return birthday <= dateToISOString(newMaxDate);
            },
            messages: {
                de: "max. Datum ist " + ' %s',
                en: "max date is " + '%s'
            }
        });
    });
}