"use strict";

import loadScript from "@elements/load-script";
import {getPrefixedDataSet} from '@elements/data-set-utils';
import {onEnterViewPort, isElementInViewport} from "@elements/viewport-utils";
import { openDatareporterConsentOverlay } from './datareporter'

const defaultOptions = {
    video: false,
    autoplayFirstVideo:true,
    animateThumb: false,
    download: false,
    counter: false,
    iframeMaxWidth: '75%',
};

const defaultSelectors = {
    base: '.js-lightbox',
    item: '.js-lightbox__item'
};

export function createInitInScope(selectors = defaultSelectors, options) {
    return function ($scope) {
        return $scope.find(selectors.base).each(function () {
            let $lightbox = $(this);
            let $lightboxItems = $lightbox.find(selectors.item);

            options = {
                ...defaultOptions,
                ...options,
                ...getPrefixedDataSet('lightbox', $lightbox)
            };

            import('lightgallery').then(function () {

                if (options.video) {
                    /* the following imports are only needed if there are videos in your lightGallery*/
                    /* IMPORTANT: Do not forget to add 'lg-video-object lg-html5' to video-elements*/
                    /* IMPORTANT: The controls attribute must be set on the <video>*/
                    /* IMPORTANT: class "js-video-js" is not necessary for videos in the lightgallery (own molecule) */

                    import('video.js').then(function (videojs) {
                        /* because lg-video needs a global instance of videojs */
                        window.videojs = videojs.default;
                    });

                    loadScript('//f.vimeocdn.com/js/froogaloop2.min.js');
                    import('lg-video');

                }

                $lightbox.lightGallery({
                    ...options,
                    selector: selectors.item,
                    videojs: options.video,
                });


                $lightbox.on('onAfterAppendSubHtml.lg', function(event, prevIndex, index){
                    let $videoItem = $('.lg-current').find('video');
                    let autoplay = $videoItem.attr('data-lightbox-autoplay') || false;
                    if (autoplay) {
                        window.requestAnimationFrame(() => {
                            $videoItem[0].play();
                        });
                    }

                    $(".js-datareporter__open-overlay").each(function() {
                        if($(this).data('handlesConsentOverlays') === 'true') {
                            return;
                        }

                        $(this).data('handlesConsentOverlays', 'true');
                        $(this).on('click', function(event) {
                            event.preventDefault();
                            console.log("opening datareporter overlay");
                            openDatareporterConsentOverlay();
                        });

                        $(this).on('touchstart', function(event) {
                            event.preventDefault();
                            console.log("opening datareporter overlay");
                            openDatareporterConsentOverlay();
                        });
                    });
                });

                $lightbox.on('onBeforeSlide.lg', function(event, prevIndex, index){
                    let $videoItem = $('.lg').find('video');
                    let autoplay = $videoItem.attr('data-lightbox-autoplay') || false;
                    if (autoplay) {
                        window.requestAnimationFrame(() => {
                            $videoItem[0].pause();
                        });
                    }
                });

                $lightbox.on('onBeforeClose.lg', function(event, prevIndex, index){
                    let $videoItem = $('.lg').find('video');
                    let autoplay = $videoItem.attr('data-lightbox-autoplay') || false;
                    if (autoplay) {
                        window.requestAnimationFrame(() => {
                            $videoItem[0].pause();
                        });
                    }
                });


                //prevent isClickable
                $lightboxItems.on('click', function (evt) {
                    evt.stopImmediatePropagation();
                });
            });
        });
    }
}

export const initInScope = createInitInScope();
