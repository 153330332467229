"use strict";

export function initInScope ($scope) {
    let $formSuccessAnchor = $scope.find(".js-anchor-element");
    if ($formSuccessAnchor.length) {
        setTimeout(function(){
            $('html, body').animate({
                scrollTop: $formSuccessAnchor.offset().top - 200
            }, 500);
        }, 500);
    }
}