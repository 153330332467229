"use strict";

export function initInScope($scope) {
    let $btn = $scope.find('.js-set-select');
    $btn.on('click', function() {
        let $this = $(this);
        let $target = $($this.data('set-select-target'));
        let optionId = $this.data('set-select-option-id');

        $target.value = $target.find(`[data-id=${optionId}]`)[0].value;
        $target.find(`option[data-id=${optionId}]`)[0].selected = true;
        $target.trigger('change');
    });
}