"use strict";

import {ISOStringToDate} from "@elements/date-utils";
import {setDate} from '@elements/datepicker'

export function initInScope($scope) {
    let $element = $scope.find('.js-set-datepicker');
    $element.each(function() {
        let trigger = $(this).attr('data-set-datepicker-trigger');
        $(this).on(trigger, function(evt) {
            evt.preventDefault();
            let $datepicker = $($(this).attr('data-set-datepicker-target')),
                datepickerValue = $(this).attr('data-set-datepicker-value');

            if(datepickerValue !== '') {
                setDate($datepicker, ISOStringToDate(datepickerValue));
            } else {
               $datepicker.val('');
            }
        });
    });
}