"use strict";

import {focus, scrollTo} from "./scrollTo";

export function initInScope ($scope) {
    let $button = $scope.find('.js-show-on-click');
    let $hideButton = $scope.find('.js-show-on-click__hide');
    let offset = matchMedia('(max-width: 767px)').matches ? 60 : 100;

    $button.on('click', function(evt) {
        evt.preventDefault();
        let $target = $($(this).data('show-on-click-target'));
        $target.attr('hidden', false);
        scrollTo($target, offset, function () {
            focus($target);
        });
    });

    $hideButton.on('click', function(evt) {
        evt.preventDefault();
        let $target = $($(this).data('show-on-click-target'));
        $target.attr('hidden', 'true');
    })
}
