"use strict";


export function initInScope ($scope) {
    let $sliders = $scope.find('.js-hero-slider');

    import('slick-carousel').then(function() {
        $sliders.each(function() {

            let $slider = $(this);
            let $sliderWrapper = $slider.closest('.hero-slider');

            $slider.slick({
                dots: true, /*should always be true; hide with css (accessibility)*/
                arrows:true,
                slidesToShow: 1,
                fade:true,
                infinite:false,
                variableWidth:false,
                centerMode: false,
                speed: 900,
                cssEase: 'ease',
                prevArrow: $sliderWrapper.find('.slick-slider__arrow--primary-square.hero-slider__arrow.arrow-prev'),
                nextArrow: $sliderWrapper.find('.slick-slider__arrow--primary-square.hero-slider__arrow.arrow-next')
            });

        });
    })


}
