"use strict";

let sliderId = 0;

export function initInScope ($scope) {
    let $sliders = $scope.find('.js-hero-blog-slider');

    import('slick-carousel').then(function() {
        $sliders.each(function() {
            let $sliderWrapper = $(this);
            let $sliderMain = $(this).find('.js-hero-blog-slider__main');
            let $sliderNav = $(this).find('.js-hero-blog-slider__nav');
            let hasThumbs = 'true';
            let id = sliderId++;

            $sliderMain.attr('id', 'hero-blog-slider__main-' + id);
            $sliderNav.attr('id', 'hero-blog-slider__thumb-' + id);

            $sliderMain.slick({
                asNavFor: '#hero-blog-slider__thumb-' + id,
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: true,
                prevArrow: $sliderWrapper.find('.slick-slider__arrow--primary-square.hero-blog-slider__arrow.arrow-prev'),
                nextArrow: $sliderWrapper.find('.slick-slider__arrow--primary-square.hero-blog-slider__arrow.arrow-next'),
                dots: true,
                fade: false,
                infinite: false,
                cssEase: 'cubic-bezier(0.4, 0, 0.3, 1)',
                speed:1200,
                focusOnSelect: true,
                responsive: [{
                    breakpoint: 767,
                    settings: {
                        asNavFor: null,
                        speed:600,
                    }
                }]
            });

            if(matchMedia('(min-width: 768px)').matches && hasThumbs === 'true') {
                if($sliderNav && $sliderNav.length) {
                    $sliderNav.slick({
                        dots: true,
                        arrows: false,
                        slidesToScroll: 1,
                        slidesToShow: 8,
                        infinite: false,
                        centerMode: false,
                        variableWidth: true,
                        asNavFor: '#hero-blog-slider__main-' + id,
                        focusOnSelect: true,
                    });
                }
            }
        });
    })


}
