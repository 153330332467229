"use strict";

export function initInScope ($scope) {
    let $previewInput = $scope.find('.js-img-preview__input');

    $previewInput.on('change', function() {
        let input = this;
        let $previewWrapper = $(this).closest('.js-img-preview');
        let $previewResult = $previewWrapper.find('.js-img-preview__result');
        let $previewLoading = $previewWrapper.find('.js-img-preview__loading');

        $previewLoading.attr('hidden', null);


        if (input.files && input.files[0]) {
            var reader = new FileReader();

            reader.onload = function(e) {
                $previewResult.attr('src', e.target.result);
                $previewLoading.attr('hidden', 'hidden');
            };

            reader.readAsDataURL(input.files[0]);
        }
    });
}
