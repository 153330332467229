const selectors = {
    totalSum: '.js-cart__total',
    singleProductForm: '.js-cart__form',
    singleProductPrice: '.js-cart__single-price',
    singleProductAmount: '.js-cart__amount',
    priceNotAvailable: '.js-cart__is-not-available',
    productCategorySelector: '.js-cart__update-price',
    numberSpinner: '.js-number-spinner'
}

import { setNumberSpinnerCount } from "./numberSpinner";

let notAvailableText = ''

export function initInScope($scope) {
    $scope.on('change', '.js-number-spinner__input', function (evt) {
        updateTotalSum()
    })

    let $cartForm = $scope.find('.js-cart__form');
    if ($cartForm && $cartForm.length) {
        let $cartUpdatePrice = $cartForm.find('.js-cart__update-price');

        $cartUpdatePrice.on('change', function(evt) {
            evt.preventDefault();

            // find the number spinner that is in the same js-cart__form as the input
            let $spinner = $(this).closest('.js-cart__form').find('.js-number-spinner');
            setNumberSpinnerCount($spinner, 0)

            updatePrice(evt, $(this).closest('.js-cart__form'))
            updateTotalSum()
        })
    }

    // try to find an element with the class js-cart__is-not-available
    let $notAvailable = $scope.find('.js-cart__is-not-available');

    // get the inner html of one of the notAvailable elements and save it into notAvailableText
    if($notAvailable && $notAvailable.length) {
        notAvailableText = $notAvailable[0].innerText
    } else {
        notAvailableText = "Produkt nicht verfügbar"
    }
}

function updatePrice(event, $cartForm) {
    const result = $cartForm.find(selectors.singleProductPrice)

    if(result && result.length) {
        const spinner = $cartForm.find(selectors.numberSpinner)
        const selectedPrice = event.target.options[event.target.selectedIndex].dataset.price;

        // update the value of singleProductPrice to the selected option dataset data-price
        if(selectedPrice.length > 0) {
            result[0].innerText = parseFloat(selectedPrice).toFixed(2).replace('.', ',') + ' €';

            if(spinner && spinner.length) spinner[0].classList.remove('numberspinner-is-disabled')
            result[0].classList.remove(selectorToClassname(selectors.priceNotAvailable))
        } else {
            result[0].innerText = notAvailableText

            if(spinner && spinner.length) spinner[0].classList.add('numberspinner-is-disabled')
            result[0].classList.add(selectorToClassname(selectors.priceNotAvailable))
        }
    }
}

function updateTotalSum() {
    let totalSum = 0
    $(selectors.singleProductForm).each(function () {
        let $form = $(this)

        // check if form exists
        if ($form.length > 0) {
            let $amount = $form.find(selectors.singleProductAmount)
            let $price = $form.find(selectors.singleProductPrice)

            // check if amount and price exists
            if ($amount.length > 0 && $price.length > 0 && priceIsAvailable($price[0])) {
                const validAmount = stringToNumber($amount[0].value)
                const validPrice = stringToNumber($price[0].innerText)

                totalSum += validPrice * validAmount
                $(selectors.totalSum).text(totalSum.toFixed(2).toString().replace(',', '.') + " €")
            }
        }
    });
}

function stringToNumber(inputstring) {
    const removedChars = inputstring.replace(/[^0-9,]/g, '')
    const replacedComma = removedChars.replace(',', '.');
    const parsed = parseFloat(replacedComma);

    return parsed;
}

function priceIsAvailable(element) {
    return !element.classList.contains(selectorToClassname(selectors.priceNotAvailable))
}

function selectorToClassname(selector) {
    return selector.substring(1)
}