import {getDate, setMinDate, setDate} from "./datepicker";

const dayInMilliseconds = 24 * 60 * 60 * 1000;

export function initInScope($scope) {
    let $from = $scope.find('.js-datepicker-range__from');

    $from.on('change', function () {
        let $this = $(this);
        let $rangeDatepicker = $this.closest('.js-datepicker-range');
        let defaultDuration = $rangeDatepicker.attr('data-datepicker-default-duration') || false;

        if (defaultDuration) {
            setDate($rangeDatepicker.find('.js-datepicker-range__to'), roundDate(new Date(getDate($this).getTime() + defaultDuration * dayInMilliseconds)));
        }

        setMinDate(
            getToInput($(this)),
            getDate($(this))
        );
    });

    $from.each(function () {
        setMinDate(
            getToInput($(this)),
            getDate($(this))
        );
    });

    return $scope.find('.js-datepicker-range');
}

function getToInput($fromInput) {
    return $fromInput.closest('.js-datepicker-range')
        .find('.js-datepicker-range__to')
}

function roundDate(date) {
    if (date) {
        return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()));
    }
}
