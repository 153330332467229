"use strict";

import { setServicePermission, registerPermissionGrantedCallback } from "@elements/cookie-permissions";


export function init() {
    $('.js-cookie-service-helpers__service-enable').on('click', function () {
        setServicePermission($(this).data('cookie-service'), true);
    });

    $('.js-cookie-service-helpers__hide-area').each(function () {
        let $area = $(this);
        registerPermissionGrantedCallback($area.data('cookie-service'), function () {
            $area.attr('hidden', 'hidden');
        });
    });
}
