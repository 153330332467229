"use strict";

export function initInScope($scope) {
    let $select = $scope.find('.js-set-hidden-input__select');
    setHiddenInput($select);

    $select.on('change', function(evt) {
        let $this = $(this);
        setHiddenInput($this);
    });
}

function setHiddenInput($this) {
    let optionId = $this.find('option:selected').data('id');
    let $form = $this.closest('.js-set-hidden-input');
    let $hiddenInput = $form.find('.js-set-hidden-input__input');
    $hiddenInput.val(optionId);
}