"use strict";

import initModulesInScope from "@elements/init-modules-in-scope";

export function initInScope($scope) {
    let $quickfinderSelect = $scope.find('.js-quickfinder__select');
    $quickfinderSelect.on('change', function () {
        showQuickfinderTemplate($(this));
    });
}

export function init() {
    let $quickfinderSelect = $('.js-quickfinder__select');

    $quickfinderSelect.each(function() {
        showQuickfinderTemplate($(this));
    });
}

function showQuickfinderTemplate($quickfinderSelect) {
    let $quickfinderSelectWrapper = $quickfinderSelect.closest('.js-quickfinder__select-wrapper'),
        $quickfinder = $quickfinderSelect.closest('.js-quickfinder'),
        $quickfinderTemplate = $quickfinder.find($quickfinderSelect.data('template')),
        $quickfinderTemplateContainer = $quickfinderSelectWrapper.find($quickfinderSelect.data('template-container')),
        preId = $quickfinderSelect.data('pre-id') || false;

    $quickfinderTemplateContainer.html('');

    if ($quickfinderSelect.val() > 0) {
        for (let i = 1; i <= $quickfinderSelect.val(); i++) {
            let htmlIndex = i;
            if (preId) {
                htmlIndex = preId + '-' + i;
            }
            let $newTemplate = createTemplate(htmlIndex, i, $quickfinderTemplate);
            $quickfinderTemplateContainer.append($newTemplate);
        }
        initModulesInScope($quickfinderTemplateContainer);
    }
}

function createTemplate(htmlIndex, index, $template) {
    let templateHtml = $template.html();
    let $newTemplate = $(templateHtml.replace(/{index}/g, index).replace(/{htmlId}/g, htmlIndex));
    $newTemplate.removeClass('js-quickfinder__child-template').addClass('js-quickfinder__child-template-added');
    return $newTemplate;
}