"use strict";


import {clearBirthdayInputs} from "./birthdayInput";

export function initInScope($scope) {
    let $resetElements = $scope.find('.js-reset-birthday-input');

    $resetElements.on("click", function(evt) {
        evt.preventDefault();
        let $target = $($(this).attr('data-reset-birthday-input-target'));

        if($target) {
            clearBirthdayInputs($target);
        }
    });
}