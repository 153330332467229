"use strict";

import $ from 'jquery';
import loadJQueryUi from '@elements/load-jquery-ui';
import {dateToISOString, ISOStringToDate, localDateToUTCDate, UTCDateToLocalDate} from "@elements/date-utils";
import {getPrefixedDataSet} from "@elements/data-set-utils";
import {translate} from "@elements/translations";

const HOUR_IN_MS = 60 * 60 * 1000;
const DAY_IN_MS = 24 * 60 * 60 * 1000;

function addKeyboardSupport($datepicker, $altField) {
    let newDate = localDateToUTCDate($datepicker.datepicker('getDate'));

    $datepicker.on('keydown', function (evt) {
        if(newDate == null) {
            $datepicker.datepicker('setDate', new Date());
            newDate = $datepicker.datepicker('getDate');
        }

        switch (evt.key) {
            case 'ArrowLeft':
                newDate.setDate(newDate.getDate() - 1);
                evt.preventDefault();
                evt.stopImmediatePropagation();
                break;
            case 'ArrowUp':
                newDate.setDate(newDate.getDate() - 7);
                evt.preventDefault();
                evt.stopImmediatePropagation();
                break;
            case 'ArrowRight':
                newDate.setDate(newDate.getDate() + 1);
                evt.preventDefault();
                evt.stopImmediatePropagation();
                break;
            case 'ArrowDown':
                newDate.setDate(newDate.getDate() + 7);
                evt.preventDefault();
                evt.stopImmediatePropagation();
                break;
            case 'PageUp':
                newDate.setDate(newDate.getDate() - 30);
                evt.preventDefault();
                evt.stopImmediatePropagation();
                break;
            case 'PageDown':
                newDate.setDate(newDate.getDate() + 30);
                evt.preventDefault();
                evt.stopImmediatePropagation();
                break;
            case 'Enter':
                newDate.setDate(newDate.getDate());
                $datepicker.datepicker('setDate', newDate);
                $altField.val(dateToISOString(localDateToUTCDate(newDate))).trigger('change');
                $datepicker.datepicker('hide');

                evt.preventDefault();
                evt.stopImmediatePropagation();
                break;
        }

        if (newDate.getTime() !== $datepicker.datepicker('getDate').getTime()) {
            $datepicker.datepicker('setDate', newDate);
        }
    });
}

// returns UTC Date
export function getDate($datepicker) {
    return ISOStringToDate($datepicker.find('.js-datepicker__alt-field').val());
}

// date: UTC DATE
export function setDate($datepicker, date) {
    loadJQueryUi().then(function () {
        $datepicker.find('.js-datepicker__alt-field').val(dateToISOString(date));
        $datepicker.find('.js-datepicker__input').datepicker('setDate', UTCDateToLocalDate(date));
    });

}

// date: UTC DATE
export function setMinDate($datepicker, date) {
    loadJQueryUi().then(function () {
        $datepicker.find('.js-datepicker__input')
            .datepicker('option', 'minDate', UTCDateToLocalDate(date));

        let currentDate = getDate($datepicker);
        if(typeof currentDate === 'undefined' || currentDate.getTime() < date.getTime()) {
            setDate($datepicker, date);
        }
    });
}

// date: UTC DATE
export function setMaxDate($datepicker, date) {
    loadJQueryUi().then(function () {
        $datepicker.find('.js-datepicker__input')
            .datepicker('option', 'maxDate', UTCDateToLocalDate(date));

        let currentDate = getDate($datepicker);
        if(typeof currentDate === 'undefined' || currentDate.getTime() > date.getTime()) {
            setDate($datepicker, date);
        }
    });
}

export function initInScope($scope) {
    const selectors = {
        base: '.js-datepicker',
        input: '.js-datepicker__input',
        altField: '.js-datepicker__alt-field'
    };

    let $datepickerContainers = $scope.find(selectors.base);
    loadJQueryUi().then(function () {
        $datepickerContainers.each(function () {
            let $container = $(this);
            let $datepicker = $container.find(selectors.input);
            let $altField = $container.find(selectors.altField);
            let hasAvailabilities = $container.hasClass('js-datepicker--has-availabilities');
            let hasNightsToAdd = $container.hasClass('js-datepicker--add-nights');
            let availabilities = {};

            if(hasAvailabilities) {
                availabilities = JSON.parse($container.attr('data-datepicker-available-date-ranges'));
                console.log("date ranges", availabilities);
            }



            let options = {
                numberOfMonths: 1,
                minDate: UTCDateToLocalDate(new Date()),
                nextText: '<span class="icon icon-arrow"></span>',
                prevText: '<span class="icon icon-arrow"></span>',
                firstDay: 1,
                showAnim: 'show', // other animations (like fadeIn) do not work with jquery.slim
                beforeShowDay: function(date) {
                    date = localDateToUTCDate(date);

                    if(hasAvailabilities) {
                        for(let i=0; i< availabilities.length; i++) {
                            if(date >= ISOStringToDate(availabilities[i].start) && date <= ISOStringToDate(availabilities[i].end)) {
                                return [true, 'state-is-available'];
                            }
                        }
                        return [false, ''];
                    }
                    return [true, ''];
                },
                onSelect: function(dateString, inst) {
                    let selectedDate = new Date(Date.UTC(inst.selectedYear, inst.selectedMonth, inst.selectedDay));
                    $altField.val(dateToISOString(selectedDate)).trigger('change');

                    if(hasNightsToAdd) {
                        let nights = $container.attr('data-datepicker-add-nights');
                        let $target = $scope.find($container.attr('data-datepicker-add-nights-target'));
                        let newEndDate = addNights(selectedDate, nights);
                        $target.val(dateToISOString(newEndDate));
                    }

                },
                ...transformDataOptions(getPrefixedDataSet('datepicker', $container))
            };

            addKeyboardSupport($datepicker, $altField);
            $datepicker.datepicker(options);

            if ($altField.val()) {
                setDate($container, ISOStringToDate($altField.val()));
            }
        });
    });
}



function transformDataOptions(options) {
    options = {...options};

    if (options.minDate && typeof options.minDate === 'string') {
        options.minDate = UTCDateToLocalDate(ISOStringToDate(options.minDate));
    }

    if (options.maxDate && typeof options.maxDate === 'string') {
        options.maxDate = UTCDateToLocalDate(ISOStringToDate(options.maxDate));
    }

    return options;
}

function roundDay(date) {
    date = new Date(date.getTime() + HOUR_IN_MS);
    return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()))
}


function getDatepickerCellSettings({date, availabilities}) {
    let cssClasses = [];
    let texts = [];
    let isAllowed = true;



    return [isAllowed, cssClasses.join(' '), texts.join(', ')];
}

function addNights(date, nights) {
    let newDate = new Date(date.getTime() + (+nights * DAY_IN_MS));
    return new Date(Date.UTC(newDate.getUTCFullYear(), newDate.getUTCMonth(), newDate.getUTCDate()))
}