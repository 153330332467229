"use strict";


export function initInScope($scope) {
    let $links = $scope.find('.js-region-hotspots__link');

    $links.on('mouseover', function () {
        let $link = $(this);
        let region = $link.attr('data-region');
        let $wrapper = $link.closest('.js-region-hotspots');

        $links.removeClass('is-active');
        $wrapper.find(`.js-region-hotspots__link[data-region='${region}']`).addClass('is-active');
    });

    $links.on('mouseout', function () {
        $links.removeClass('is-active');
    });
}