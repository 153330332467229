"use strict";

let isRunning = false;
let $navbarAffix;
let lastScrollPosition = 0;
let isNavOverlayOpen = false;


export function init() {
    let $navbar = $('.js-affix-navbar');
    let $navbarContainer = $navbar.closest('.js-affix-navbar__placeholder');
    let navbarHeight;

    let initiallyRelative = $navbar.hasClass('main-nav--initial-relative');
    let defaultNavbarHeight = initiallyRelative ? '90' : '0';

    $(window).one('scroll', function () {
        $navbarContainer.css('overflow', 'hidden');
        navbarHeight = $navbarContainer[0].getBoundingClientRect().height || defaultNavbarHeight;
        $navbarContainer.css('overflow', '');
        /*get exact height (jquery returns rounded value)*/
        $navbarContainer.height(navbarHeight);
    });

}
export function initInScope($scope) {
    $navbarAffix = $scope.find('.js-affix-navbar');

    if ($navbarAffix) {
        navbarToggleAffix($navbarAffix);
    }
}

function navbarToggleAffix($navbarAffix) {
    if (!isRunning) {
        isRunning = true;
        requestAnimationFrame(interval);
    }
}

function interval() {
    let scrollTopPosition = $(window).scrollTop();
    if (lastScrollPosition === scrollTopPosition || isNavOverlayOpen) {
        requestAnimationFrame(interval);
        return;
    }

    lastScrollPosition = scrollTopPosition;
    updateDom();
    requestAnimationFrame(interval);
}

function updateDom() {
    let offset = $('.js-affix-navbar__placeholder').offset().top;
    let scrollTopPosition = $(window).scrollTop();

    $navbarAffix.isAffix = offset < scrollTopPosition;

    if ($navbarAffix.isAffix) {
        setAffix();
    } else {
        unsetAffix();
    }
}

function setAffix() {
    $navbarAffix.addClass('is-affix');
    $navbarAffix.addClass('is-nav-white');
}

function unsetAffix() {
    $navbarAffix.removeClass('is-affix');
    $navbarAffix.removeClass('is-nav-white');
}
