"use strict";

export function init () {
    if(!_config.openModalByDefaultId) {
        console.warn('_config.openModalByDefaultId is not defined');
        return;
    }

    let $modal = $(_config.openModalByDefaultId);

    if($modal && $modal.length > 0) {
        if(localStorage.getItem('open-modal-by-default-reminder-date') && _config.openModalByDefaultDays) {
            let currentDate = Math.round((new Date()).getTime() / 1000);
            let reminderDate = +localStorage.getItem('open-modal-by-default-reminder-date');

            console.log("openModal Reminder Date:", new Date(reminderDate * 1000));

            if (currentDate > reminderDate) {
                localStorage.removeItem('open-modal-by-default-reminder-date');
                localStorage.removeItem('open-modal--already-opened');
                $modal.modal('show');
            } else {
                //console.log('do nothing');
            }
        } else {
            $modal.modal('show');
        }
    } else {
        console.warn('openModalByDefault: Modal ' + _config.openModalByDefaultId + ' not found!');
    }

    $modal.on('hidden.bs.modal', function (event) {
        let $button = $(`[data-target='${_config.openModalByDefaultId}'`);
        $button.addClass('badge-animation');
        $button.on('animationend', function() {
            $button.removeClass('badge-animation');
        });

        if(localStorage && _config.openModalByDefaultDays) {
            let storingDays = _config.openModalByDefaultDays || 1;
            let today = Math.round((new Date()).getTime() / 1000);
            let futureDate = today + (+storingDays*24*60*60);

            localStorage.setItem('open-modal-by-default-reminder-date', futureDate.toString());
        }

    });
}
