"use strict";

import {preloadSlide, preloadNSlide} from "@elements/slider";
import {onEnterViewPort} from "@elements/viewport-utils";

export function initInScope ($scope) {
    let $sliders = $scope.find('.js-img-slider');

    import('slick-carousel').then(function() {
        $sliders.each(function() {

            let $slider = $(this);
            let $sliderWrapper = $slider.closest('.img-slider');
            let itemCount = $slider.find('.slider-item').length;

            onEnterViewPort($slider, function ($slider) {
                preloadNSlide($slider.slick('getSlick'), 1, 1);
            });

            $slider.slick({
                dots: true, /*should always be true; hide with css (accessibility)*/
                arrows:true,
                slidesToShow: 1,
                infinite:false,
                initialSlide: itemCount < 3 ? 0 : 1,
                variableWidth:true,
                centerMode: true,
                speed: 900,
                cssEase: 'ease',
                prevArrow: $sliderWrapper.find('.img-slider__arrow.arrow-prev'),
                nextArrow: $sliderWrapper.find('.img-slider__arrow.arrow-next')
            }).on('afterChange', function (event, slick, currentSlide) {
                preloadNSlide(slick, currentSlide + 1, 1);
            });

        });
    })


}
