"use strict";

export function initInScope ($scope) {

    $(document).ready(function() {
        $('.js-tracking--impression').each(function() {
            let action = $(this).data('tracking-action');
            let href = $(this).attr('href');

            let dataLayerData = {
                event: "genericGtmEvent",
                genericEventCategory: "impression_recommended_accommodation",
                genericEventAction: action,
                genericEventLabel: href,
            };
            console.log(dataLayerData, 'dataLayerData');
            dataLayer.push(dataLayerData);
        });
    });
    $scope.find('.js-tracking--click').on('click', function () {
        let action = $(this).data('tracking-action');
        let href = $(this).attr('href');


        let dataLayerData = {
            event: "genericGtmEvent",
            genericEventCategory: "click_recommended_accommodation",
            genericEventAction: action,
            genericEventLabel: href,
        };
        console.log(dataLayerData, 'dataLayerData');
        dataLayer.push(dataLayerData);
    });

}
