import {unsetAffix, setAffix} from './affixNavbar';

export function init() {
    if(matchMedia('(min-width: 1200px)').matches) {
        return;
    }


    $('.js-nav__link').on('click', function (evt) {
        let $currentLink = $(this);
        let $nav = $currentLink.closest('.js-nav');
        let $navItems = $nav.find('.js-nav__item');
        let $navItem = $currentLink.closest('.js-nav__item');

        evt.preventDefault();

        if($navItem.hasClass('is-open')) {
            $navItem.removeClass('is-open');
            $nav.removeClass('is-open');
        } else {
            $nav.addClass('is-open');
            $navItems.removeClass('is-open');
            $navItem.addClass('is-open');
        }
    });

    $('.js-nav__close').on('click', function () {
        let $close = $(this);
        let $nav = $close.closest('.js-nav');
        let $navItems = $nav.find('.js-nav__item');

        $navItems.removeClass('is-open');
        closeNav();
    });

    $('.js-nav__toggle').on('click', function () {
        
        let $close = $(this);
        let $nav = $close.closest('.js-nav');
        let $navItems = $nav.find('.js-nav__item');

        $nav.toggleClass('is-open');
        $navItems.removeClass('is-open');

        if($nav.hasClass('is-open')) {
           openNav();
        } else {
           closeNav();
        }
    });

    $('.js-nav__back').on('click', function(evt)  {
        let $back = $(this);
        let $navItem = $back.closest('.js-nav__item');

        evt.preventDefault();

        $navItem.removeClass('is-open');

    });
}

function closeNav() {
    let $body = $('body');

    $('html').removeClass('is-scrolling-disabled');

    if (matchMedia('(max-width: 767px)').matches) {
        let scrollPosition = +$body.css('top').replace('-', '').replace('px', '');
        $body.css('top', '');
        window.scrollTo(0, scrollPosition);
    }
}

function openNav() {
    let $body = $('body');

    if (matchMedia('(max-width: 767px)').matches) {
        $body.css('top', `-${document.documentElement.scrollTop || document.body.scrollTop}px`);
    }
    $('html').addClass('is-scrolling-disabled');
}