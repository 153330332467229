"use strict";

import {isElementInViewport} from "@elements/viewport-utils";
let lastScrollPosition = 0;
let isRunning = false;
let isAffix = false;

export function initInScope ($scope) {
    if(matchMedia('(max-width: 1199px)').matches) {
        return;
    }
    if (!isRunning) {
        isRunning = true;
        requestAnimationFrame(interval);
    }
}

function interval() {
    if (lastScrollPosition === window.scrollY) {
        requestAnimationFrame(interval);
        return;
    }

    lastScrollPosition = window.scrollY;
    requestAnimationFrame(interval);
    updatePosition();
}

function updatePosition() {
    if(!isElementInViewport($('.js-affix-media'), $('.js-affix-media').height()) && $('.js-affix-media').hasClass('is-playing')) {
        $('.js-affix-media__video-wrapper').addClass('is-affix');
        isAffix = true;
    } else if(isElementInViewport($('.js-affix-media'))) {
        $('.js-affix-media__video-wrapper').removeClass('is-affix');
    }
}

export function unsetAffixMedia() {
    $('.js-affix-media__video-wrapper').removeClass('is-affix');
}

