import {isParsleyForm, isValid} from "@elements/parsley-bootstrap-validation";
import {getPrefixedDataSet} from "@elements/data-set-utils";
import 'url-polyfill';
import 'url-search-params-polyfill'; // Edge Polyfill
import fetch from '@elements/fetch'; // IE10 Polyfill
import { debounce } from "debounce";
import asyncAppend from '@elements/async-append';
import mapObject from "object.map";
import {focus, scrollTo} from "./scrollTo";

const defaultSelectors = {
    base: '.js-ajax-form',
    result: '.js-ajax-form__result',
    loading: '.js-ajax-form__loading',
    notifications: '.js-ajax-form__notifications',
    form: '.js-ajax-form__form',
    additionalForm: '.js-ajax-form__additional-form',
    errorArea: '.js-ajax-form__error-area',
    retry: '.js-ajax-form__retry',
    link: '.js-ajax-form__link'
};

const defaultOptions = {
    submitOnChange: false,
    addUrlParams: true
};

export function createInitInScope(options = defaultOptions, selectors = defaultSelectors) {
    return function ($scope) {
        return getElementBySelector(selectors.base, $scope).each(function () {
            createAjaxForm($(this), selectors, options);
        });
    }
}

export function createAjaxForm($baseElement, selectors = defaultSelectors, options = defaultOptions) {
    let $elements = getElementsBySelectorObject(selectors, $baseElement);

    let pendingRequest = null;

    options = {
        ...defaultOptions,
        ...options,
        ...getPrefixedDataSet('ajax-form', $baseElement)
    };


    if (options.submitOnChange) {
        $elements.form.on('change', debounce(() => $elements.form.trigger('submit'), 200));
        $elements.additionalForm.on('change', debounce(() => $elements.additionalForm.trigger('submit'), 200));
    }

    $elements.retry.on('click', function (evt) {
        evt.preventDefault();

        if (lastLoadParams) {
            load(...lastLoadParams);
        }
    });

    addLinkClickHandler(getElementBySelector(selectors.link, $baseElement));
    addFormSubmitHandler(getElementBySelector(selectors.form, $baseElement));
    addFormSubmitHandler(getElementBySelector(selectors.additionalForm, $baseElement));

    function addLinkClickHandler($links) {
        $links.on('click', function (evt) {
            evt.preventDefault();

            let href = $(this).attr('href') || $(this).data('href');
            let action = $elements.form.data('action') || $elements.form.attr('action');
            let params = new URL(href, location.origin).searchParams;

            let $target = $(this).closest('.js-ajax-form__result');
            let offset = 100;
            scrollTo($target, offset, function () {
                focus($target);
            });

            pendingRequest = load(action, 'GET', params, href);
        });
    }

    function addFormSubmitHandler($forms) {
        let $elements = getElementsBySelectorObject(selectors, $baseElement);

        if (options.submitOnChange && $elements.additionalForm) {
            $elements.additionalForm.on('change', debounce(() => $elements.additionalForm.trigger('submit'), 200));
        }

        $forms.on('submit', function (evt) {
            if (pendingRequest && pendingRequest.abort) {
                pendingRequest.abort();
                pendingRequest = null;
            }

            evt.preventDefault();

            let appendFormData;
            if($elements.additionalForm) {
                appendFormData = new FormData($baseElement.find('.js-ajax-form__additional-form')[0]);
            }
            
            if (isParsleyForm($elements.form) && !isValid($elements.form)) {
                return;
            }

            evt.stopImmediatePropagation(); // otherwise .on('submit.ajax-form') would be called twice
            $baseElement.trigger('submit.ajax-form');

            let action = $elements.form.data('action') || $elements.form.attr('action');
            let method = $elements.form.data('method') || $elements.form.attr('method');
            let formData = new FormData($elements.form[0]);

            if($elements.additionalForm) {
                for(let data of appendFormData.entries()) {
                    formData.append(data[0], data[1]);
                }
            }

            let params = new URLSearchParams(formData);

            let url = new URL(location.href);
            url.searchParams.delete('page');
            url = addSearchParamsToUrl(url, params);

            pendingRequest = load(action, method, params, url);

            pendingRequest
                .then(() => pendingRequest = null)
                .catch((error, requestState) => {
                    if (error.name !== 'AbortError' // native fetch abort
                        && requestState !== 'abort') { // jquery abort
                        pendingRequest = null;
                    }
                });
        });

    }


    let lastLoadParams = null; // for retry
    function load(url, method = "GET", params, historyUrl) {
        lastLoadParams = [url, method, params, historyUrl];

        let $elements = getElementsBySelectorObject(selectors, $baseElement);
        $baseElement.trigger('fetch.ajax-form');

        if (options.addUrlParams) {
            history.replaceState(history.state, document.title, historyUrl || url);
        }

        if (method.toUpperCase() === "GET") {
            url = addSearchParamsToUrl(url, params);
        }

        let request = fetch(url, {
            method: method,
            ...(method.toUpperCase() !== "GET" ? {
                body: new URLSearchParams(params)
            } : {})
        });

        let promise = request.then(response => (response.json && typeof response.json === 'function')
            ? response.clone().json()
            : response);

        asyncAppend(
            {$target: $elements.result, $loading: $elements.loading, $notifications: $elements.notifications},
            promise
        )
            .then((result) => {
                let content = result.html || result.content;
                if (content && result.success !== false) {
                    $baseElement.trigger('success.ajax-form');
                    $elements.errorArea.attr('hidden', 'hidden');
                    addLinkClickHandler(getElementBySelector(selectors.link, $baseElement));
                    addFormSubmitHandler(getElementBySelector(selectors.additionalForm, $baseElement));
                } else {
                    $baseElement.trigger('failed.ajax-form');
                    $elements.errorArea.attr('hidden', null);
                }
            });

        promise.catch((error, requestState) => {
            if (error.name !== 'AbortError' // native fetch abort
                && requestState !== 'abort') { // jquery abort
                $baseElement.trigger('failed.ajax-form');
                $elements.errorArea.attr('hidden', null);
            }
        });

        return request;
    }

    return $baseElement;


    // let api = {
    //     submit: () => console.log('submit !!!!')
    // };
    //
    // $baseElement.data('ajax-form', api);
    //
    // return api;
}

function addSearchParamsToUrl(url, searchParams) {
    url = new URL(url, location.origin);

    let searchParamsArray = Array.from(searchParams);
    searchParamsArray.forEach(([name]) => url.searchParams.delete(name));
    searchParamsArray.forEach(([name, value]) => url.searchParams.append(name, value));

    return url;
}

export const initInScope = createInitInScope();

// function serialize(form, options) {
//     let defaults = {
//         include: [],
//         exclude: []
//     };
//
//     let config = Object.assign({}, defaults, options);
//     let data = {};
//
//     for (let element of form.elements) {
//         let tag = element.tagName;
//         let type = element.type;
//         if (tag === 'INPUT' && (type === 'password' || type === 'file')) {
//             continue // do not serialize passwords or files
//         }
//         if (isNameFiltered(element.name, config.include, config.exclude)) {
//             continue
//         }
//         if (tag === 'INPUT') {
//             let type = element.type;
//             if (type === 'radio') {
//                 if (element.checked) {
//                     pushToArray(data, element.name, element.value)
//                 }
//             } else if (type === 'checkbox') {
//                 pushToArray(data, element.name, element.checked)
//             } else {
//                 pushToArray(data, element.name, element.value)
//             }
//         } else if (tag === 'TEXTAREA') {
//             pushToArray(data, element.name, element.value)
//         } else if (tag === 'SELECT') {
//             if (element.multiple) {
//                 for (let option of element.options) {
//                     if (option.selected) {
//                         pushToArray(data, element.name, option.value)
//                     }
//                 }
//             } else {
//                 pushToArray(data, element.name, element.value)
//             }
//         }
//     }
//     return data
// }
//
// function deserialize(form, data, options) {
//     let defaults = {
//         valueFunctions: null,
//         include: [],
//         exclude: []
//     };
//
//     let config = Object.assign({}, defaults, options);
//
//     // apply given value functions first
//     let speciallyHandled = [];
//     if (config.valueFunctions !== null) {
//         speciallyHandled = applySpecialHandlers(data, form, config)
//     }
//     // fill remaining values normally
//     for (let name in data) {
//         if (isNameFiltered(name, config.include, config.exclude)) {
//             continue;
//         }
//
//         if (!speciallyHandled.includes(name)) {
//             let inputs = [...form.elements].filter(elem => elem.name === name);
//             inputs.forEach((input, i) => {
//                 applyValues(input, data[name], i)
//             })
//         }
//     }
// }
//
// function isNameFiltered(name, include, exclude) {
//     if (!name) {
//         return true
//     }
//
//     if (exclude.includes(name)) {
//         return true
//     }
//
//     return include.length > 0 && !include.includes(name);
// }
//
// function pushToArray(dict, key, value) {
//     if (!(key in dict)) {
//         dict[key] = []
//     }
//     dict[key].push(value)
// }

export function normalizeSelector(selector) {
    switch (typeof selector) {
        case 'string':
            return $container => $container.find(selector)
                .add($container.is(selector) ? $container: null);
        case 'function':
            return selector;
        default:
            return (selector instanceof $)
                ? () => selector
                : () => $(selector);
    }
}

export function getElementBySelector(selector, $container) {
    return normalizeSelector(selector)($container)
}

export function getElementsBySelectorObject(selectorObject, $container) {
    return mapObject(selectorObject, selector => getElementBySelector(selector, $container));
}