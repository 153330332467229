"use strict";

let $anchorAccordion
let hash = $(location).attr('hash');

export function initInScope($scope) {
    if(hash) {
        $anchorAccordion = $scope.find(hash);
        $anchorAccordion.find(".collapse").collapse();
    }
}