"use strict";

export function initInScope($scope) {
    let $nestedCheckboxWrapper = $scope.find('.js-nested-checkbox__wrapper'),
        $checkboxes = $nestedCheckboxWrapper.find('.js-nested-checkbox__checkbox');

    $checkboxes.on('click', function () {
        let $clickedCheckbox = $(this);
        $nestedCheckboxWrapper = $(this).closest('.js-nested-checkbox__wrapper');
        let checkedCount = $nestedCheckboxWrapper.find('.js-nested-checkbox__checkbox:not(.js-nested-checkbox__checkbox--main):checked').length;
        let $nestedCheckboxes = $nestedCheckboxWrapper.find('.js-nested-checkbox__checkbox');
        $checkboxes = $nestedCheckboxWrapper.find('.js-nested-checkbox__checkbox');

        if($clickedCheckbox.hasClass('js-nested-checkbox__checkbox--main')) {
            if($clickedCheckbox.is(':checked')) {
                $nestedCheckboxes.prop('checked', true);
            } else {
                $nestedCheckboxes.prop('checked', false);
            }
        } else {
            let $mainCheckbox = $nestedCheckboxWrapper.find('.js-nested-checkbox__checkbox--main');
            if (checkedCount > 0 && checkedCount < $nestedCheckboxes.length) {
                $mainCheckbox.prop('checked', false);
            } else if (checkedCount > 0) {
                $mainCheckbox.prop('checked', true);
            }
        }
    });
}