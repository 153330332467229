"use strict";

export function initInScope($scope) {
    // initially disable or enable the buttons based on value -> starting state with disabled '-' button for example
    $scope.find('.js-number-spinner').each(function () {
        enableDisableButtons($(this));
    })

    $scope.find('.js-number-spinner__down').on('click', function (evt) {
        console.log("here"); 
        let $spinner = $(this).closest('.js-number-spinner');
        let $input = $spinner.find('.js-number-spinner__input');
        let newValue;

        newValue = +$input.val() - 1;

        if ($input.attr('min')) {
            newValue = Math.max(+$input.attr('min'), newValue);
        }

        $input.attr('value',newValue);
        $input.val(newValue);
        $input.trigger('change', newValue);
        evt.preventDefault();
    });

    $scope.find('.js-number-spinner__up').on('click', function (evt) {
        let $spinner = $(this).closest('.js-number-spinner');
        let $input = $spinner.find('.js-number-spinner__input');
        let newValue;

        newValue = +$input.val() + 1;

        if ($input.attr('max')) {
            newValue = Math.min(+$input.attr('max'), newValue);
        }

        $input.attr('value',newValue);
        $input.val(newValue);
        $input.trigger('change', newValue);

        // updateTotalSum()
        evt.preventDefault();
    });

    $scope.on('change','.js-number-spinner__input', function(evt) {
        let $spinner = $(this).closest('.js-number-spinner');
        let $input = $spinner.find('.js-number-spinner__input');
        let newValue = +$input.val();

        if ($input.attr('max')) {
            newValue = Math.min(+$input.attr('max'), newValue);
        }

        if ($input.attr('min')) {
            newValue = Math.max(+$input.attr('min'), newValue);
        }

        $input.attr('value',newValue);
        $input.val(newValue);

        enableDisableButtons($spinner);
        // updateTotalSum()
    });
}

function enableDisableButtons($spinner) {
    if (!$spinner.is('.js-number-spinner')) {
        console.error('Can\'t en-/disable buttons for element', $spinner, 'Element is not a number spinner ".js-number-spinner"');
        return;
    }

    let $input = $spinner.find('.js-number-spinner__input');
    let $btUp = $spinner.find('.js-number-spinner__up');
    let $btDown = $spinner.find('.js-number-spinner__down');
    let inputVal = +$input.val();

    if ($input.attr('max')) {
        if(+$input.attr('max') == inputVal) {
            $btUp.attr('disabled', 'disabled');
        } else {
            $btUp.attr('disabled', null);
        }
    }

    if ($input.attr('min')) {
        if(+$input.attr('min') == inputVal) {
            $btDown.attr('disabled', 'disabled');

        } else {
            $btDown.attr('disabled', null);
        }
    }
}

export function setNumberSpinnerCount($spinner, count) {
    if (!$spinner.is('.js-number-spinner')) {
        console.error('Can\'t set count of element', $spinner, 'Element is not a number spinner ".js-number-spinner');
        return;
    }

    $spinner.find('.js-number-spinner__input').attr('value',count) // to set the value in the markup

    const inputElement =  $spinner.find('.js-number-spinner__input')[0]
    inputElement.value = count // to set the displayed value

    enableDisableButtons($spinner);
}

export function setNumberSpinnerMaxValue($spinner, maxValue) {
    if (!$spinner.is('.js-number-spinner')) {
        console.error('Can\'t set count of element', $spinner, 'Element is not a number spinner ".js-number-spinner');
        return;
    }

    $spinner.find('.js-number-spinner__input').attr('max', maxValue);
    enableDisableButtons($spinner);
}

export function getNumberSpinnerMaxValue($spinner, maxValue) {
    if (!$spinner.is('.js-number-spinner')) {
        console.error('Can\'t set count of element', $spinner, 'Element is not a number spinner ".js-number-spinner');
        return;
    }

    return $spinner.find('.js-number-spinner__input').attr('max');
}
export function getNumberSpinnerValue($spinner, maxValue) {
    if (!$spinner.is('.js-number-spinner')) {
        console.error('Can\'t set count of element', $spinner, 'Element is not a number spinner ".js-number-spinner');
        return;
    }

    return $spinner.find('.js-number-spinner__input').val();
}
