"use strict";

import {isValid} from '@elements/parsley-bootstrap-validation';

export function initInScope ($scope) {
    let $container = $scope.find('.js-cropper'),
        $cropper = $container.find('.js-cropper__cropper'),
        $dropZones = $container.find('.js-cropper__drop-zone'),
        $input = $container.find('.js-cropper__input'),
        $inputCropperData = $container.find('.js-cropper__input-image-data'),
        $files = $container.find('.js-cropper__upload-files'),
        $fullSize = $container.find('.js-cropper__full-size'),
        $rotateLeft = $container.find('.js-cropper__rotate--left'),
        $rotateRight = $container.find('.js-cropper__rotate--right'),
        $cropperSubmit = $container.find('.js-cropper__submit'),
        pendingRequest,
        ajaxUrl = $cropperSubmit.data('cropper-ajax-url'),
        $loading = $container.find('.js-cropper__loading'),
        $result = $container.find('.js-cropper__result'),
        $disableSubmit = $container.data('cropper-disable-submit') || false,
        $submitButton = $container.closest('form').find('[type="submit"]');


    import('cropperjs').then(function (cropperjs) {
        let Cropper = cropperjs.default;
        let cropper;

        $fullSize.on('click', function(e) {
            e.preventDefault();
            e.stopImmediatePropagation();

            let height = cropper.getImageData().naturalHeight;
            let width = cropper.getImageData().naturalWidth;
            cropper.reset();
            cropper.setData({
                x: 0,
                y: 0,
                width: width,
                height: height
            });
        });

        $rotateLeft.on('click', function(e) {
            e.preventDefault();
            e.stopImmediatePropagation();
            cropper.rotate(-90);
        });

        $rotateRight.on('click', function(e) {
            e.preventDefault();
            e.stopImmediatePropagation();
            cropper.rotate(90);
            cropper("getCanvasData");
        });


        let options = {
            aspectRatio: 3/4,
            viewMode: 2,
            center:true,
            autoCropArea: 0.9,
            minContainerHeight: 200,
            guides: true,
            checkOrientation: false,
            dragMode: "move",
            ready: function () {
                if (!window.File || !window.FileReader || !window.FileList || !window.Blob) {
                    alert('Cropper is not supported in this browser.');
                    return;
                }

                $files.one('change', function (e) {
                    if($disableSubmit) {
                        $submitButton.attr('disabled', true);
                    }
                    $cropperSubmit.attr('disabled', true);
                    handleFileSelect(e);
                });

                $dropZones
                    .on('dragover', function (event) {
                        event.stopPropagation();
                        event.preventDefault();
                        $(this).addClass('img-cropper-dropzone--over');
                    })
                    .on('dragleave', function (event) {
                        $(this).removeClass('img-cropper-dropzone--over');
                    })
                    .on('drop', function (event) {
                        event.preventDefault();
                        // $cropperSubmit.attr('disabled', true);
                        handleFileSelect(event);
                        $(this).removeClass('img-cropper-dropzone--over');
                    });
            }
        };

        cropper = new Cropper($cropper[0], options);
        

        $cropperSubmit.on('click', function(e) {
            e.preventDefault();
            e.stopImmediatePropagation();
            $loading.attr('hidden', null);
            if($disableSubmit) {
                $submitButton.attr('disabled', true);
            }

            let picture;
            picture = getCroppedImg();

            $input.val(picture);
            $inputCropperData.val(JSON.stringify(cropper.getImageData()));
            let picData = $input.serialize();

            pendingRequest = $.ajax(ajaxUrl, {
                data: picData,
                type: "POST",
                processData:false,
                cache:false,
            }).done(function (data) {
                $result.attr('hidden', false);
                $result.attr('src', picture);
                if($disableSubmit) {
                    $submitButton.attr('disabled', false);
                }
            }).fail(function(a){
                console.warn(a);
            }).always(function () {
                pendingRequest = null;
                $loading.attr('hidden', 'hidden');
            });
        });

        function getCroppedImg() {
            let canvas = cropper.getCroppedCanvas({
                maxWidth: 800,
                fillColor: '#fff'
            });

            let picture = canvas.toDataURL('image/jpeg');
            return picture;
        }

        function handleFileSelect(event) {
            event.stopPropagation();
            event.preventDefault();
            $loading.attr('hidden', null);

            let reader = new FileReader();
            reader.onerror = function () {
                switch (evt.target.error.code) {
                    case evt.target.error.NOT_FOUND_ERR:
                        alert('File Not Found!');
                        break;
                    case evt.target.error.NOT_READABLE_ERR:
                        alert('File is not readable');
                        break;
                    case evt.target.error.ABORT_ERR:
                        break; // noop
                    default:
                        alert('An error occurred reading this file.');
                }
            };

            reader.onabort = function () {
                alert('Reading file was cancelled');
                $cropperSubmit.attr('disabled', null);
                $loading.attr('hidden', 'hidden');
            };

            reader.onload = function (e) {
                if (cropper) {
                    cropper.replace(reader.result);
                } else {
                    $cropper.attr('hidden', null).attr('aria-hidden', false);
                    $cropper.attr('src', reader.result);
                    cropper = new Cropper($cropper[0], options);
                }
                $cropperSubmit.attr('disabled', null);
                $loading.attr('hidden', 'hidden');
            };

            if (event.target.files) {
                reader.readAsDataURL(event.target.files[0]);
            } else {
                reader.readAsDataURL(event.originalEvent.dataTransfer.files[0]);
            }

        }
    });
}
