"use strict";


export function init() {
    window.addEventListener('message', function (msg) {
        if (msg.data === "getHeight") {
            parent.postMessage({'height': getHeight()},'*');
        }
    });

    document.addEventListener('DOMContentLoaded', function() {
        parent.postMessage({'height': getHeight()},'*');
    }, false);

    window.addEventListener('resize', function () {
        parent.postMessage({'height': getHeight()},'*');
    });

    
    function getHeight() {
        var body = document.body;
        var html = document.documentElement;

        return Math.max( body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight );
    }

}
