"use strict";

import {dateToISOString, localDateToUTCDate} from "@elements/date-utils";

export function initInScope ($scope) {
    let $birthday = $scope.find(".js-birthday-input");
    let $birthdayAlt = "";

    $birthday.each(function() {
        let $this = $(this);
        let $inputs = $this.find('.js-birthday-input__element');
        let $birthdayDay = $this.find(".js-birthday-input__day");
        let $birthdayMonth = $this.find(".js-birthday-input__month");
        let $birthdayYear = $this.find(".js-birthday-input__year");
        let $birthdayInputAlt = $this.find(".js-birthday-input__alt");
        let $birthdayInvalidDateMessage = $this.find(".js-birthday-input__invalid-date-message");

        if($birthdayInputAlt.val().length) {
            $birthdayDay.val(new Date($birthdayInputAlt.val()).getDate());
            $birthdayMonth.val((new Date($birthdayInputAlt.val()).getMonth()) + 1);
            $birthdayYear.val(new Date($birthdayInputAlt.val()).getFullYear());
        }

        $inputs.on('change', function(){
            if ($birthdayDay.val() && $birthdayMonth.val() && $birthdayYear.val()) {
                $birthdayAlt = concatDate($birthdayDay, $birthdayMonth, $birthdayYear);
                if(!$birthdayAlt) {
                    $birthdayInvalidDateMessage.removeAttr("hidden");
                } else {
                    $birthdayInputAlt.val($birthdayAlt);
                    $birthdayInputAlt.trigger("input");
                    $birthdayInvalidDateMessage.attr("hidden", "hidden");
                }
            }
        });
        if(detectFirefox()) {
            openDataListFirefox($inputs);
        }
    });
}


function concatDate($day, $month, $year) {
    let day = $day.val();
    let month = +$month.val() - 1;
    let year = $year.val();
    let d = new Date(year, month, day);

    if (d.getFullYear() == year && d.getMonth() == month && d.getDate() == day) {
        return dateToISOString(localDateToUTCDate(new Date(year, month, day)), true);
    }
    return false;
}

function openDataListFirefox($inputs) {
    $inputs.each(function () {
        let $this = $(this);
        $this.on("focus", function (e) {
            $this.next(".js-birthday-input__datalist").css("display", "block");
            $this.next(".js-birthday-input__datalist").css("height", "0px");
            $this.parent().css("overflow", "hidden");
        });
        $this.on("focusout", function (e) {
            $this.next(".js-birthday-input__datalist").css("display", "none");
        });
    });
}

function detectFirefox() {
    let userAgent = navigator.userAgent;
    let browserName;
    if (userAgent.match(/firefox|fxios/i)) {
        browserName = 'Firefox';
        return browserName;
    }
    return false;
}

export function clearBirthdayInputs($birthday) {
    $birthday.each(function() {
        let $this = $(this);
        let $inputs = $this.find('.js-birthday-input__element');
        let $birthdayDay = $this.find(".js-birthday-input__day");
        let $birthdayMonth = $this.find(".js-birthday-input__month");
        let $birthdayYear = $this.find(".js-birthday-input__year");
        let $birthdayInputAlt = $this.find(".js-birthday-input__alt");

        $inputs.val("");
        $birthdayDay.val("");
        $birthdayMonth.val("");
        $birthdayMonth.val("");
        $birthdayYear.val("");
        $birthdayInputAlt.val("");
    });
}