"use strict";

export function initInScope($scope) {
    $scope.find('.js-expandable').each(function () {
        let $expandableElement = $(this);

        let $tabParent = $expandableElement.closest('.tab-pane');
        if ($tabParent && $tabParent.length && !$tabParent.hasClass('active')) {
            let id = $tabParent.attr('id');
            let $tabTrigger = $('[data-toggle="tab"][href="#' + id + '"], [data-toggle="tab"][data-target="#' + id + '"]');

            $tabTrigger.on('shown.bs.tab', function () {
                initCollapseElement($expandableElement)
            });

            return;
        }

        initCollapseElement($expandableElement);
    });
};

function initCollapseElement($expandableElement) {
    let expandableContent = $expandableElement.find('.js-expandable__content-wrapper');
    if (expandableContent[0].scrollHeight > expandableContent.outerHeight()  + 30) {
        $expandableElement.removeClass('is-not-expandable');
        $expandableElement.addClass('is-expandable is-collapsed');

        $expandableElement.find('.js-expandable__more').on('click', function (evt) {
            evt.preventDefault();
            $expandableElement.removeClass('is-collapsed');
        }).on('click', function (evt) {
            evt.preventDefault();
        });
    } else if (expandableContent[0].scrollHeight != 0) { // if display none
        $expandableElement.addClass('is-not-expandable').removeClass('is-collapsed');
    }
}
