"use strict";
import loadScript from "@elements/load-script";

let currentId = 1;
export function initInScope ($scope) {
    let $recaptcha =  $scope.find('.js-recaptcha');

    window.grecaptchaOnload = function () {
        $recaptcha.each(function () {
            let $this = $(this),
                id = $(this).prop('id');

            if (!id) {
                id = 'recaptcha-id-' + currentId++;
                $(this).prop('id', id);
            }

            let widget = grecaptcha.render($(this)[0], {
                'sitekey': $(this).data('sitekey'),
                'callback': reCaptchaVerify
            });

            let $form = $(this).closest("form");

            addValidation($form, widget);

            function reCaptchaVerify() {
                console.log('reCaptchaVerify');
            }
            
            $this.closest('.js-ajax-form').on('failed.ajax-form', function () {
                grecaptcha.reset(widget);
            });
        });
    };

    loadRecaptchaAPI().done(function () {
        if (typeof window.grecaptcha.render === 'function') {
            grecaptchaOnload();
        }
    });
}

export function loadRecaptchaAPI() {
    return loadScript('https://www.google.com/recaptcha/api.js?onload=grecaptchaOnload&hl=' + _config.lang);

}

function addValidation($form, recaptcha) {
    $form.find('[type=submit]').on('click', function (evt) {
        $form.find('.js-recaptcha__input').val('');

        if(grecaptcha.getResponse().length === 0) {
            $form.find('.js-recaptcha__input').val('');
        } else {
            $form.find('.js-recaptcha__input').val(grecaptcha.getResponse());
        }
    });
}
