"use strict";

import { showModal as showCookieModal } from "@elements/cookie-permissions";


export function initInScope($scope) {
    $scope.find('.js-show-cookie-modal').on('click', function (evt) {
        evt.preventDefault();

        showCookieModal();
    });
}
