"use strict";

let requestList = [];

export function initInScope ($scope) {
    let $container = $scope.find('.js-accommodation-request__container'),
        $countResult = $scope.find('.js-accommodation-request__counter'),
        $resultList = $scope.find('.js-accommodation-request__list'),
        $btn = $scope.find('.js-accommodation-request__add-btn'),
        $form = $resultList.closest('form'),
        sessionStorageName = $container.attr('data-session-storage-name') || 'accommodation-request',
        $emptyMessage = $scope.find('.js-accommodation-request__empty-message'),
        $submitBtn = $form.find('button:submit');

    try {
        requestList = [];
        let data = JSON.parse(sessionStorage.getItem(sessionStorageName));
        data.forEach(function(item, key) {
            addToList(item.id, item.title);
        });

        updateSubmitButton();
        updateButtons(data);
    } catch(err) {}

    // remove sessionstorage-item if form is submitted
    $form.on('submit', function(evt) {
        try {
           sessionStorage.removeItem(sessionStorageName);
        } catch(err) {}
    });

    $btn.on('click', function(evt) {
        let $this = $(this);
        if($this.hasClass('active')) {
            $this.removeClass('active');
            removeFromList($this.data('accommodation-request-id'), $this.data('accommodation-request-title'))

        } else {
            $this.addClass('active');
            addToList($this.data('accommodation-request-id'), $this.data('accommodation-request-title'))
        }
    });


    function addToList(id, title) {
        let newItem = {
            id: id,
            title: title
        };

        if(requestList.indexOf(newItem) === -1) {
            requestList.push(newItem);
        }

        try {
            sessionStorage.removeItem(sessionStorageName);
            sessionStorage.setItem(sessionStorageName, JSON.stringify(requestList));
            renderItem(newItem);
        } catch(err) {}

        updateSubmitButton();
    }

    function renderItem(item) {
        let markup = `<div class="accommodation-item js-accomoodation-request__item" data-accommodation-request-id="${item.id}"> <button type="button" class="js-accommodation-request__remove accommodation-item__remove btn-no-styling"><span class="icon icon-close" aria-label="remove" title="remove"></span></span></button><div class="accommodation-item__title">${item.title}</div><input type="input" name="accommodation-request[]" value="${item.id}" hidden></div>`;

        $resultList.append($(markup));
        updateCounter(Object.keys(requestList).length);
        addRemoveHandler($(markup))
    }

    function addRemoveHandler() {
        let $removeTrigger = $('.js-accommodation-request__remove');

        $removeTrigger.on('click', function() {
            removeFromList($(this).closest('.js-accomoodation-request__item').data('accommodation-request-id'));
        })
    }

    function removeFromList(id) {
        $container.find(`[data-accommodation-request-id='${id}']`).removeClass('active');
        $resultList.find(`[data-accommodation-request-id='${id}']`).remove();
        requestList = requestList.filter(el => el.id !== id);

        try {
            sessionStorage.removeItem(sessionStorageName);
            sessionStorage.setItem(sessionStorageName, JSON.stringify(requestList));
        } catch(err) {}

        updateCounter(Object.keys(requestList).length);
        updateSubmitButton();

    }

    function updateSubmitButton() {
        try {
            if(requestList.length > 0) {
                $emptyMessage.attr('hidden', 'hidden');
                $submitBtn.attr('disabled', false);
            } else {
                $emptyMessage.attr('hidden', false);
                $submitBtn.attr('disabled', true);
            }
        } catch(err) {}
    }

    function updateCounter(counter) {
        $countResult.empty().html(counter);
    }

    function updateButtons(data) {
        data.forEach(function(item,key) {
           let id = item.id;
            $container.find(`[data-accommodation-request-id='${id}']`).addClass('active');
        });
    }
}