"use strict";

import {add} from '@elements/scroll-animations';

export function initInScope($scope) {
    let $waypointNavItems = $scope.find('.js-scroll-waypoints__nav-item');

    $waypointNavItems.on('click', function (evt) {
        evt.preventDefault();
        let $waypoint = $(this);
        if (!$waypoint.hasClass('locked')) {
            $waypointNavItems.removeClass('active');
            $waypoint.addClass('active');
        }
    });

    add(
        $scope.find('.js-scroll-waypoints__nav-item'),
        calculateAnimationProgress,
        setAnimationProgress
    );
}

function calculateAnimationProgress(element) {
    const start = window.innerHeight;
    const end = 0;

    let contentElement = $($(element).attr('href'));

    return 1 - Math.max(
        Math.min(
            (contentElement[0].getBoundingClientRect().top - end) / (start - end),
            1
        ),
        0
    );
}

function setAnimationProgress(element, progress) {
    let $el = $(element);
    let $waypointWrapper = $el.closest('.js-scroll-waypoints__nav');
    let progressStart = matchMedia('(max-width: 767px)').matches ? 0.1 : 0.6;
    if (progress > progressStart && progress < 1) {
        if (!$el.closest('.js-scroll-waypoints__nav-item').hasClass('active')) {
            $el.closest('.js-scroll-waypoints__nav').find('.js-scroll-waypoints__nav-item').removeClass('active');
            $el.closest('.js-scroll-waypoints__nav-item').addClass('active');
            $el.closest('.js-scroll-waypoints__nav-item').removeClass('locked');
            centerWaypoint($el, $waypointWrapper);
        }
    }
}

function centerWaypoint(waypoint, waypointWrapper) {
    if(waypoint && waypoint.length) {
        let childPos = waypoint.offset().left,
            parentScroll = waypointWrapper.scrollLeft(),
            offset = childPos + parentScroll - window.outerWidth/2 + waypoint.width()/2;

        waypointWrapper.animate({ scrollLeft: offset }, 0);
    }
}